import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Substrings from '../blogs/Substrings';
import Beer from '../contents/Beer';
import Blockchain from '../contents/Blockchain';
import Me from '../contents/Me';
import Mobile from '../contents/Mobile';
import Ongoing from '../contents/Ongoing';
import Web from '../contents/Web';
import useStyles from '../themes/stylesHook';
import Game from '../contents/Game';
import Camino from 'src/contents/Camino';

export default function () {
	const classes = useStyles();
	return <main className={classes.content}>
		<div className={classes.toolbar} />
		<Switch>
			<Route path="/me">
				<Me />
			</Route>
			<Route path="/ongoing">
				<Ongoing />
			</Route>
			<Route path="/blockchain">
				<Blockchain />
			</Route>
			<Route path="/web">
				<Web />
			</Route>
			<Route path="/mobile">
				<Mobile />
			</Route>
			<Route path="/game">
				<Game />
			</Route>
			<Route path="/beer">
				<Beer />
			</Route>
			<Route path="/camino">
				<Camino />
			</Route>
			<Route path="/substrings">
				<Substrings />
			</Route>
			<Route path="/">
				<Me />
			</Route>
		</Switch>
	</main>
}
