import {Avatar, Container} from '@material-ui/core';
import Heading from '../pallets/Heading';
import List from '../pallets/List';
import Text from '../pallets/Text';
import React from 'react';
import wixLogo from 'res/images/wix.png'
import useStyles from './styles';
import omzugLogo from 'res/images/omzug.png'

export default function Web(){
	const classes = useStyles();
	const list = [
		{
			imageNode: <Avatar alt="Wix Logo" src={wixLogo} className={classes.avatar}/>,
			title: 'Wix Frontend Engineer',
			subtitle: 'Having fun in developing and maintaining the Wix flag product: Wix Website Editor. Working on the new components and features.',
			details: 'Mar. 2017 - Apr.2018',
		},
		{
			imageNode: <Avatar alt="Omzug Logo" src={omzugLogo} className={classes.avatar}/>,
			title: 'Omzug apartment renting platform',
			subtitle: 'Omzug is a web application for renting room or apartment, which aims at Chinese in Germany.',
			details: 'Sep. 2018 - June. 2019',
			onClick: ()=>{window.open("http://xinyue.de/omzug", "_self")}
		}
	];

	return (
		<Container>
			<Heading title={'Web'}/>
			<Text content={'Start with HTML and CSS from middle school, I like building applications which can be feel and play. Now I mostly use React as my building tool'}/>
			<List listItems={list}/>
		</Container>
	)
}
