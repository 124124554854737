import {Container} from '@material-ui/core';
import React from 'react';
import Heading from '../pallets/Heading';
import List from '../pallets/List';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import LaunchIcon from '@material-ui/icons/Launch';
import EmailIcon from '@material-ui/icons/Email';
import Text from '../pallets/Text';

const listItems = [
	{
		imageNode: <LinkedInIcon/>,
		title: 'LinkedIn',
		subtitle: '',
		details: '',
		onClick: () => {
			window.open("https://www.linkedin.com/in/hanwen-cheng/", "_self")
		}
	},
	{
		imageNode: <GitHubIcon/>,
		title: 'Github',
		subtitle: '',
		details: '',
		onClick: () => {
			window.open("https://github.com/hanwencheng", "_self")
		}
	},
	{
		imageNode: <LaunchIcon/>,
		title: 'Medium',
		subtitle: '',
		details: '',
		onClick: () => {
			window.open("https://medium.com/@heawen110", "_self")
		}
	},
	{
		imageNode: <EmailIcon/>,
		title: 'Email',
		subtitle: '',
		details: '',
		onClick: () => {
			window.open("mailto:heawen.cheng@gmail.com")
		}
	}
];

export default function Me(){
	return (
		<Container>
			<Heading title="About Me"/>
			<Text content={'I am the founder of Litentry Technologies GmbH in Berlin, Germany. I am keen on the decentralized technology and open source community.'}/>
			<List listItems={listItems}/>
		</Container>
	)
}
