import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		text: {
			marginTop: theme.spacing(4),
		},
		mediaRoot: {
			maxWidth: 540,
		},
		mediaContent: {
			height: 540,
		},
	})
);

export default useStyles;
