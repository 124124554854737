import {Avatar, Container} from '@material-ui/core';
import React from 'react';
import Heading from '../pallets/Heading';
import Image from '../pallets/Image';
import List from '../pallets/List';
import Text from '../pallets/Text';
import litentry from 'res/images/camino2.jpg';
import zhihu from 'res/images/zhihu.png';

const listItem = [{
	imageNode: <Avatar alt="Litentry Logo" src={zhihu}/>,
	title: 'Post About Camino on Zhihu (in Chinese)',
	subtitle: 'In this post I detailed the experience on the way',
	details: '',
	onClick: ()=>{window.open("https://www.zhihu.com/question/32280207/answer/63376793", "_self")}
}];

export default function Camino(){
	return (
		<Container>
			<Heading title={'Camino de Santiago'}/>
			<Text content={'In May and June 2015, I went on the way to Santiago de Compostela (Camino de Santiago) with two of my friends, start from St Jean Pied de Port in France to Santiago in Spain, we have walked about 700 km in 30 days'}/>
			<Image imageSource={litentry} title={'Camino'} text={'Rest on the way'}/>
			<List listItems={listItem}/>
		</Container>
	)
}
