import {createStyles, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		avatar: {
			backgroundColor: '#fff'
		}
	})
);

export default useStyles;
