import {SideBarItems} from './types';

const routes: SideBarItems = [
	{
		icon: '👾',
		text: 'Me',
		route: '/me',
	},
	{
		icon: '🛠',
		text: 'Ongoing Projects',
		route: '/ongoing',
	},
	{
		icon: '🔐',
		text: 'Blockchain',
		route: '/blockchain',
	},
	{
		icon: '📱',
		text: 'Mobile',
		route: '/mobile',
	},
	{
		icon: '🎮',
		text: 'Games',
		route: '/game',
	},
	{
		icon: '🖥',
		text: 'Web',
		route: '/web',
	},
	{
		icon: '🏔',
		text: 'Camino de Santiago',
		route: '/camino',
	},
	{
		icon: '🍺',
		text: 'Beer Brewing',
		route: '/beer',
	}
];

export default routes;
