import {Avatar, Container} from '@material-ui/core';
import GitHubIcon from '@material-ui/icons/GitHub';
import signer from 'res/images/signer.png';
import Heading from '../pallets/Heading';
import List from '../pallets/List';
import Text from '../pallets/Text';
import React from 'react';
import blockweise from 'res/images/blockweise.png';

const list = [
	{
		imageNode: <Avatar alt="Parity Signer Logo" src={signer}/>,
		title: 'Parity Signer on React Native',
		subtitle: 'Air-gapped crypto wallet for Substrate and Ethereum, made by React Native',
		details: 'started at July 2019',
		onClick: ()=>{window.open("https://www.parity.io/signer/", "_self")}
	},
	{
		imageNode: <GitHubIcon style={{ fontSize: 40 }}/>,
		title: 'Substrate Signing Flutter Plugin',
		subtitle: 'Flutter plugin which wrapped the Rust Signing code for Substrate',
		details: 'Apr. 2020',
		onClick: ()=>{window.open("https://github.com/hanwencheng/substrate_sign_flutter", "_self")}
	},
	{
		imageNode: <GitHubIcon style={{ fontSize: 40 }}/>,
		title: 'ECC encryption module on React Native',
		subtitle: 'A hardware-backed encryption and decryption library for React Native',
		details: 'Oct. 2019',
		onClick: ()=>{window.open("https://github.com/paritytech/React-Native-ECEncryption", "_self")}
	},
	{
		imageNode: <Avatar alt="Blockweise Logo" src={blockweise}/>,
		title: 'Blockweise Wallet',
		subtitle: 'Jingtum crypto wallet, used for offchain signing, made by React Native',
		details: '',
		onClick: ()=>{window.open("https://www.blockweise.com/signer", "_self")}
	},
	{
		imageNode: <GitHubIcon style={{ fontSize: 40 }}/>,
		title: 'Genesis Space Mobile App',
		subtitle: 'A self regulated community mobile application based on Ethereum, made by React Native',
		details: 'Sep. 2018 - June. 2019',
		onClick: ()=>{window.open("https://github.com/hanwencheng/GenesisMobile", "_self")}
	},
	{
		imageNode: <GitHubIcon style={{ fontSize: 40 }}/>,
		title: 'Everi-deliver Project',
		subtitle: 'A decentralized delivery mobile app build on Longhash Hackathon Berlin 2018, is top on the topic and win the prize, youtube video: https://www.youtube.com/watch?v=0rCU6QiDUsQ',
		details: 'Oct. 2018',
		onClick: ()=>{window.open("https://github.com/IronBlockchain/everi-deliver", "_self")}
	}
];

export default function Mobile(){

	return (
		<Container>
			<Heading title={'Mobile'}/>
			<Text content={'I start to use React Native for daily work since 2018, and start to exploring Flutter since 2020, specialized at crypto wallet.'}/>
			<List listItems={list}/>
		</Container>
	)
}
