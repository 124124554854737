import {Avatar, Collapse, useTheme} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import routes from './routes';
import blogs from './blogs';
import React from 'react';
import useStyles from '../themes/stylesHook';
import {ExpandLess, ExpandMore } from '@material-ui/icons';
import { useHistory } from "react-router-dom";

function TransparentAvatar ({emoji}: {emoji: string}){
	const classes = useStyles();
	return <Avatar className={classes.listAvatar}>{emoji}</Avatar>;
}

function DrawerInner({onClose}: {onClose: ()=>void}){
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);
	const history = useHistory();

	const handleClick = () => {
		setOpen(!open);
	};

	const handlePageClick = (pageRoute: string): void => {
		onClose();
		history.push(pageRoute);
	};

	return <div>
		<div className={classes.toolbar} />
		{/*<Divider />*/}
		<List>
			{routes.map((item, index) => (
				<ListItem button onClick={()=>handlePageClick(item.route)} key={'SideBarList' + index}>
					<ListItemIcon>
						<TransparentAvatar emoji={item.icon}/>
					</ListItemIcon>
					<ListItemText primary={item.text}/>
				</ListItem>
			))}
			<ListItem button onClick={handleClick}>
				<ListItemIcon>
					<TransparentAvatar emoji={'✏️'}/>
				</ListItemIcon>
				<ListItemText primary="Blogs" />
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{blogs.map((blog, index)=>(
						<ListItem button key={'SideBarListBlog' + index} onClick={()=>handlePageClick(blog.route)}>
							<ListItemText primary={blog.text} />
						</ListItem>
					))}
				</List>
			</Collapse>
		</List>
	</div>
}
// const container = window !== undefined ? () => window.document.body : undefined;

export default function Side({mobileOpen, handleDrawerClose}: {
	mobileOpen: boolean;
	handleDrawerClose: ()=> void;
}){
	const theme = useTheme();
	const classes = useStyles();
	console.log('modal open is', mobileOpen);

	return <div className={classes.drawer} aria-label="mailbox folders">
		{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
		<Hidden smUp implementation="css">
			<Drawer
				variant="temporary"
				anchor={theme.direction === 'rtl' ? 'right' : 'left'}
				open={mobileOpen}
				onClose={handleDrawerClose}
				classes={{
					paper: classes.drawerPaper,
				}}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
			>
				<DrawerInner onClose={handleDrawerClose}/>
			</Drawer>
		</Hidden>
		<Hidden xsDown implementation="css">
			<Drawer
				classes={{
					paper: classes.drawerPaper,
				}}
				variant="permanent"
				open
			>
				<DrawerInner onClose={handleDrawerClose}/>
			</Drawer>
		</Hidden>
	</div>
}
