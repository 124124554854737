import {BlogItems} from './types';

const blogs: BlogItems = [
	{
		text: 'Find common substrings',
		route: '/substrings'
	}
];

export default blogs;
