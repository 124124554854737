import Typography from '@material-ui/core/Typography';
import React from 'react';
import useStyles from './styles';

export default function Text({content}:{content: string}){
	const classes = useStyles();
	return (
		<Typography paragraph className={classes.text}>
			{content}
		</Typography>
	);
}

export function CustomText(props: any){
	const classes = useStyles();
	return (
		<Typography paragraph className={classes.text} {...props}/>
	);
}
