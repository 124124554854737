import {Container} from '@material-ui/core';
import Heading from '../pallets/Heading';
import Image from '../pallets/Image';
import Text from '../pallets/Text';
import React from 'react';
import wuxia from 'res/images/wuxia.png';

export default function Game(){
	return (
		<Container>
			<Heading title={'Game'}/>
			<Text content={'I like RPG and RTS games, and I see games as a way to telling stories and make fun.'}/>
			<Text content={ 'I want to create a game to let the users create their own stories, and let others play his/her story in the game.' +
			 "Now I'm developing a sandbox game based on Wuxia Stories (武侠) with Unity."}/>
			<Image imageSource={wuxia} title="Wuxia game in develop" text="
				The game is a sandbox world, their is two mode, in editor mode user could create stories in different scene and generate characters, and upload their stories.
				In play mode user use his created role to play in different scenes.
			"/>
		</Container>
	)
}
