import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import Content from './components/Content';
import Side from './components/Side';
import './App.css';
import {ThemeProvider} from '@material-ui/core/styles';
import {
  BrowserRouter as Router
} from "react-router-dom";

import 'typeface-roboto';
import strings from './components/strings';
import useStyles from './themes/stylesHook';
import theme from './themes/theme';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-60973146-6');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" color="inherit" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {strings.header}
              </Typography>
            </Toolbar>
          </AppBar>
          <Side mobileOpen={mobileOpen} handleDrawerClose={handleDrawerClose}/>
          <Content/>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
