import {Container} from '@material-ui/core';
import GitHubIcon from '@material-ui/icons/GitHub';
import Heading from '../pallets/Heading';
import Image from '../pallets/Image';
import List from '../pallets/List';
import Text from '../pallets/Text';
import React from 'react';
import gopherBeer from 'res/images/gopherBeerLogo.png';

const list = [
	{
		imageNode: <GitHubIcon style={{ fontSize: 40 }}/>,
		title: 'Homebrew Docs',
		subtitle: 'Learning notes on homebrewing',
		details: '',
		onClick: ()=>{window.open("https://github.com/hanwencheng/jiu/tree/master/docs/basics", "_self")}
	}
];

export default function Beer(){
	return (
		<Container>
			<Heading title={'Beer'}/>
			<Text content={'I start brew beer since 2018, and I have locally a website to log all the learning in homebrew, and has build some tools for calculate the ingredients, these tools are not deployed on internet yet.'}/>
			<Image imageSource={gopherBeer} title="jiu.hanwencheng.com" text="" onClick={()=>window.open("https://jiu.hanwencheng.com/", "_self")}/>
			<List listItems={list}/>
		</Container>
	)
}
