import {Avatar, Container} from '@material-ui/core';
import Heading from '../pallets/Heading';
import React from 'react';
import List from '../pallets/List';
import litentry from 'res/images/litentry.png'
import signer from 'res/images/signer.png'
import GitHubIcon from '@material-ui/icons/GitHub';
import {CustomText} from '../pallets/Text';

const lists = [
	{
		imageNode: <Avatar alt="Litentry Logo" src={litentry}/>,
		title: 'Litentry',
		subtitle: 'Authorization and Data Exchange Infrastructure on Polkadot Substrate, supported by Web3 Foundation',
		details: 'started at Aug. 2019',
		onClick: ()=>{window.open("https://www.litentry.com", "_self")}
	},
	{
		imageNode: <Avatar alt="Parity Signer Logo" src={signer}/>,
		title: 'Parity Signer',
		subtitle: 'Air-gapped crypto wallet for Substrate and Ethereum',
		details: 'started at July 2019',
		onClick: ()=>{window.open("https://www.parity.io/signer/", "_self")}
	},
	{
		imageNode: <GitHubIcon style={{ fontSize: 40 }}/>,
		title: 'Substrate API Flutter Plugin',
		subtitle: 'Flutter plugin which wrapped the Polkadot.js API code for Substrate',
		details: 'started at April 2020',
		onClick: ()=>{window.open("https://github.com/hanwencheng/substrate_api_flutter", "_self")}
	},
	{
		imageNode: <GitHubIcon style={{ fontSize: 40 }}/>,
		title: 'Unity Wuxia Game',
		subtitle: 'A sandbox Wuxia game with turn based combat',
		details: 'started at May 2020',
		onClick: ()=>{}
	}
];


export default function (){
	return (
	<Container>
			<Heading title={'Current Ongoing Projects'} />
		<CustomText>
			Followings are the projects I am currently working on, if you find any is interesting and want to join or discuss,
			feel free to contact me, happy to share!
		</CustomText>
			<List listItems={lists}/>
	</Container>
	);
}
