import {List as OriginList, ListItem, ListItemAvatar, ListItemText, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {ListItems} from '../pallets/types';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
		},
		inline: {
			display: 'inline',
		},
	})
);

export default function List ({listItems}:{listItems: ListItems}){
	const classes = useStyles();
	return (<OriginList className={classes.container}>
		{listItems.map((listItem)=>(
			<ListItem alignItems="center" button onClick={listItem.onClick}>
				<ListItemAvatar>
					{listItem.imageNode}
				</ListItemAvatar>
				<ListItemText
					primary={listItem.title}
					secondary={
						<React.Fragment>
							<Typography
								variant="body2"
								color="textPrimary"
							>
								{listItem.subtitle}
							</Typography>
							<Typography
								variant="body2"
								color="textPrimary"
							>
								{listItem.details}
							</Typography>
						</React.Fragment>
					}
				/>
			</ListItem>
		))}
	</OriginList>)
}
