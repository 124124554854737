import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		drawer: {
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth,
				flexShrink: 0,
			},
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			[theme.breakpoints.up('sm')]: {
				zIndex: theme.zIndex.drawer + 1,
				flexShrink: 0,
			},
		},
		menuButton: {
			marginRight: theme.spacing(2),
			[theme.breakpoints.up('sm')]: {
				display: 'none',
			},
		},
		// necessary for content to be below app bar
		toolbar: theme.mixins.toolbar,
		drawerPaper: {
			width: drawerWidth,
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
		description: {
			padding: theme.spacing(2)
		},
		nested: {
			paddingLeft: theme.spacing(4),
		},
		listAvatar: {
			fontSize: 30,
			background: 'rgba(0, 0, 0, 0)'
		},
		listIcon: {
			fontSize: 40,
			background: 'rgba(0, 0, 0, 0)'
		}
	}),
);

export default useStyles;
