import {Avatar, Container} from '@material-ui/core';
import Heading from '../pallets/Heading';
import List from '../pallets/List';
import {CustomText} from '../pallets/Text';
import React from 'react';
import JingtumLogo from 'res/images/jingtum.png';
import ethereumLogo from 'res/images/ethereum.jpg';
import parity from 'res/images/parity.png';
import useStyles from './styles';
import blockweise from 'res/images/blockweise.png';
export default function Blockchain(){
	const classes = useStyles();

	const list = [
		{
			imageNode: <Avatar alt="Parity Logo" src={parity} className={classes.avatar}/>,
			title: 'Work in Parity',
			subtitle: 'In parity I am responsible for Parity Signer, and setting up mobile engineering related tools based on Substrate, mobile light client, flutter related tools, etc.',
			details: 'Sep. 2015 - 2017',
			onClick: ()=>{window.open("https://www.parity.io/", "_self")}
		},
		{
			imageNode: <Avatar alt="Ethereum Logo" src={ethereumLogo}/>,
			title: 'Genesis Space',
			subtitle: 'The startup project "The Genesis Space" is based on Ethereum, which is a Self Regulated Group mobile application based on Ethereum. The autonomous group are based on smart contract, they could have their own taxation, member management rules, plus they could also add variant Dapps onto it. It is mobile app in React Native, and combine crypto wallet, chat function and the Ethereum smart contract interactiion & visualization. I was responsible for the mobile implementation.',
			details: 'Sep. 2018 - June. 2019',
		},
		{
			imageNode: <Avatar alt="Blockweise Logo" src={blockweise}/>,
			title: 'Jingtum Europe Community Leader',
			subtitle: 'Directing potential projects with Jingtum blockchain and its ecosystem.\n' +
				'Cooperating and managing partnership in Europe.\n' +
				'Organizing attendances in trade show, conferences and talks.',
			details: 'Aug. 2018 - Apr. 2019',
			onClick: ()=>{window.open("https://www.blockweise.com/", "_self")}
		},
		{
			imageNode: <Avatar alt="Jingtum Logo" src={JingtumLogo} className={classes.avatar}/>,
			title: 'Jingtum Blockchain API and SDK Development',
			subtitle: 'Responsible for the REST API framework: create request queue, build database with mongoDb, build the signature verification part of the API.\n' +
				'Build cipher in Javascript SDK.\n' +
				'Responsible for the Big Data construction: use lambda architecture to build the distributed database, with Apache Hadoop, Apache Storm, Apache Kafka, Apache Cassandra, which is aimed in large concurrency use case, which is easy to scale.',
			details: 'May. 2015 - Mar. 2017',
			onClick: ()=>{window.open("https://www.swtcos.com/", "_self")}
		}
	];

	return (
		<Container>
			<Heading title={'Blockchain'}/>
			<CustomText>
				I start with the community of <a href="https://www.swtcos.com/">Jingtum Blockchain</a> in China, start to
				do crypto and API engineering since 2015. In 2018 I started the <a href="https://www.blockweise.com/swtc-introduction">
				Jingtum Germany Community.</a> and start doing business around Europe.
				Now I am working on building ecosystem tools based on <a href="https://www.parity.io/substrate/">Substrate</a> and building Project <a href="https://www.litentry.com/">Litentry</a>.
			</CustomText>

			<List listItems={list}/>
		</Container>
	)
}
