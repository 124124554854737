import {createMuiTheme} from '@material-ui/core';

const theme = createMuiTheme({
	palette: {
		type: 'dark',
		common: {
		}
	},
	typography: {

	}
});

export default theme;
