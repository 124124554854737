import {Card, CardActionArea, CardContent, CardMedia, Typography} from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

export default function Image({
	imageSource,
	title,
	text,
	onClick = () => {}
}:{
	imageSource: string;
	title: string;
	text: string;
	onClick?: () => any;
}){
	const classes = useStyles();
	return <Card className={classes.mediaRoot} onClick={onClick}>
		<CardActionArea>
			<CardMedia
				className={classes.mediaContent}
				image={imageSource}
				title={title}
			/>
			<CardContent>
				<Typography gutterBottom variant="h5" component="h2">
					{title}
				</Typography>
				<Typography variant="body2" component="p">
					{text}
				</Typography>
			</CardContent>
		</CardActionArea>
	</Card>
}
